import { Theme } from 'styled-components'

import { MediaQuery } from '../types/styled'

export const getMediaQueryStyles = (query: MediaQuery | number, styleName: string) => {
	if (typeof query === 'number') {
		return `${styleName}: ${query}px;`
	}

	return `
		${query.xs ? `@media(max-width: 575px) { ${styleName}: ${query.xs}px }` : ''}
		${query.sm ? `@media(min-width: 576px) { ${styleName}: ${query.sm}px }` : ''}
		${query.md ? `@media(min-width: 768px) { ${styleName}: ${query.md}px }` : ''}
		${query.lg ? `@media(min-width: 992px) { ${styleName}: ${query.lg}px }` : ''}
		${query.xl ? `@media(min-width: 1200px) { ${styleName}: ${query.xl}px }` : ''}
		${query.xxl ? `@media(min-width: 1600px) { ${styleName}: ${query.xxl}px }` : ''}
	`
}

export const theme: Theme = {
	borderRadius: '5px',

	colors: {
		primaryBackground: '#FFFFFF',
		secondaryBackground: '#E6F7E7',
		secondaryErrorBackground: '#FDEBF1',
		alternateBackground: '#faf9f9',
		errorBackground: '#FEE3E9',

		primaryColor: '#0FBF34',

		primaryGradient: 'linear-gradient(94.75deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0) 100.7%), #0FBF34',
		disabledGradient: 'linear-gradient(94.75deg, rgba(0, 0, 0, 0.168) 0%, rgba(0, 0, 0, 0) 100.7%), #DADADA',

		primaryLinkColor: '#3B725F',

		fontColor: '#2F2F2F',
		fontGrayColor: '#585757',
		lightFontColor: '#777777',
		placeholderFontColor: '#9F9F9F',

		borderColor: '#E8E8E8',

		secondaryBorderColor: '#C1E9C9',

		breadcrumbColor: '#9F9F9F',
		stepTitleBackgroundColor: '#ECFAEF',

		errorColor: '#EC0150',

		primaryBoxShadow: '0 24px 49px rgba(0, 0, 0, 0.07), 0 11.6894px 26.8046px rgba(0, 0, 0, 0.0473005)'
	}
}
