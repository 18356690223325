import { MAP_PIN_TYPE } from '../../../../enums/common'

const iconMap = {
	[MAP_PIN_TYPE.CISTENIE]: require('./CISTENIE.png'),
	[MAP_PIN_TYPE.DENDROLOGICKY_POSUDOK]: require('./DENDROLOGICKY_POSUDOK.png'),
	[MAP_PIN_TYPE.HAVARIJNY_VYRUB]: require('./HAVARIJNY_VYRUB.png'),
	[MAP_PIN_TYPE.INJEKTAZ_INVAZNYCH_DREVIN]: require('./INJEKTAZ_INVAZNYCH_DREVIN.png'),
	[MAP_PIN_TYPE.KRIK]: require('./KRIK.png'),
	[MAP_PIN_TYPE.KRIK_NADOBA]: require('./KRIK_NADOBA.png'),
	[MAP_PIN_TYPE.NAHRADNA_VYSADBA]: require('./NAHRADNA_VYSADBA.png'),
	[MAP_PIN_TYPE.ODSTRANENIE_PADNUTEHO_STROMY]: require('./ODSTRANENIE_PADNUTEHO_STROMY.png'),
	[MAP_PIN_TYPE.ODSTRANENIE_PNA]: require('./ODSTRANENIE_PNA.png'),
	// [MAP_PIN_TYPE.ODVOZ_BIOODPADU]: require('./ODVOZ_BIOODPADU.png'),
	[MAP_PIN_TYPE.OREZ_KROV]: require('./OREZ_KROV.png'),
	[MAP_PIN_TYPE.OREZ_STROMOV]: require('./OREZ_STROMOV.png'),
	[MAP_PIN_TYPE.STROM]: require('./STROM.png'),
	[MAP_PIN_TYPE.STROM_NADOBA]: require('./STROM_NADOBA.png'),
	[MAP_PIN_TYPE.VYRUB]: require('./VYRUB.png'),
	[MAP_PIN_TYPE.VYRUB_INVAZNYCH_DREVIN]: require('./VYRUB_INVAZNYCH_DREVIN.png'),
	[MAP_PIN_TYPE.VYSADBA]: require('./VYSADBA.png'),
	[MAP_PIN_TYPE.VYSADBA_2021]: require('./VYSADBA.png')
}

export default iconMap
