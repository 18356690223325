import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { map } from 'lodash'

import i18next from 'i18next'
import moment from 'moment'

import { State } from './reducer'
import { StateType } from '../types/state'
import { MapMarker } from '../types/data'

import { map as mapApi } from '../api'

import iconMap from '../assets/images/icons/map'

type FilterValue = string | number

type Filters = {
	districts: { value: FilterValue | null }
	type: { value: FilterValue[] }
	care: { value: FilterValue[] }
}

export type MapState = {
	mapPins: StateType<{ pins: MapMarker[] }>
	filters: Filters
}

const initialState: MapState = {
	mapPins: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	filters: {
		districts: { value: null },
		type: { value: [] },
		care: { value: [] }
	}
}

const loadMapPins = createAsyncThunk('map/fetchPins',
	async (filters: object = {}) => {
		const { data } = await mapApi.loadMapPins(filters)

		return map(data.pins, item => ({
			title: item.name.slovak,
			subTitle: item.name.latin,
			descriptionOne: `${i18next.t(`enums.MAP_PIN_TYPE.${item.type}`)}, ${item.street}`,
			district: item.district,
			descriptionTwo: i18next.t('map.pin.date', { date: moment(item.date, 'YYYY-MM-DD').format('D.M.YYYY') }),
			pictureSrc: iconMap[item.type],
			isIcon: true,
			type: item.type,
			lat: item.lat,
			lng: item.lng,
			quantity: item.quantity,
			markerIconSrc: iconMap[item.type]
		}))
	}
)

export const mapSlice = createSlice({
	name: 'map',
	initialState,
	reducers: {
		saveFilters: (state: MapState, action: PayloadAction<Filters>) => {
			state.filters = action.payload
		}
	},
	extraReducers: builder => {
		builder.addCase(loadMapPins.pending, (state) => {
			state.mapPins = { ...state.mapPins, isLoading: true, isFailure: false }
		})

		builder.addCase(loadMapPins.rejected, (state) => {
			state.mapPins = { ...state.mapPins, isLoading: false, isFailure: true }
		})

		builder.addCase(loadMapPins.fulfilled, (state, { payload }) => {
			state.mapPins = { isLoading: false, isFailure: false, data: { pins: payload } }
		})
	}
})

export const getMapPins = (state: State) => state.map.mapPins
export const getMapFilters = (state: State) => state.map.filters

export const mapActions = {
	...mapSlice.actions,
	loadMapPins
}
